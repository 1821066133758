import React from "react";
import Layout from "../other/layout";
import SEO from "../other/seo";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Img from "gatsby-image";
import FadeIn from 'react-fade-in';
import { graphql, useStaticQuery } from "gatsby";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
const FrequentlyAskedQuestions = () => {
  const intl = useIntl();
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      faq: file(relativePath: { eq: "faq.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }      
    }
  `);
  return (
    <Layout>
      <FadeIn>
        <SEO title={intl.formatMessage({ id: "innerpage-151" })} />
        <div>
          <FadeIn delay={200} transitionDuration={700}>
            <div className="mainlyt">
              <Img fluid={data.faq.childImageSharp.fluid}
                className="img-responsive"
              />
            </div>
          </FadeIn>
          <div>
            <div className="bodyTextCss">
              <FadeIn className="BodyContainer" delay={210} transitionDuration={500}>

                <div className="">
                  <div className="assessment">{intl.formatMessage({ id: "innerpage-151" })}</div>
                </div>


                <div className={classes.root}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}><b className="fontf">{intl.formatMessage({ id: "innerpage-152" })}</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <span className="fontfl">
                          {intl.formatMessage({ id: "innerpage-153" })}
                        </span>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}><b className="fontf">{intl.formatMessage({ id: "innerpage-154" })}</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography><span className="fontfl">
                      {intl.formatMessage({ id: "innerpage-155" })}
          </span></Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}><b className="fontf">{intl.formatMessage({ id: "innerpage-156" })}</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography><span className="fontfl">
                      {intl.formatMessage({ id: "innerpage-157" })}
          </span></Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}><b className="fontf">{intl.formatMessage({ id: "innerpage-158" })}</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography><span className="fontfl">
                      {intl.formatMessage({ id: "innerpage-159" })}
          </span></Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}><b className="fontf">{intl.formatMessage({ id: "innerpage-160" })}</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography><span className="fontfl">
                      {intl.formatMessage({ id: "innerpage-161" })}
          </span></Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}><b className="fontf">{intl.formatMessage({ id: "innerpage-162" })}</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography><span className="fontfl">
                      {intl.formatMessage({ id: "innerpage-163" })}
          </span></Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}><b className="fontf">{intl.formatMessage({ id: "innerpage-164" })}</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography><span className="fontfl">
                      {intl.formatMessage({ id: "innerpage-165" })}
          </span></Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}><b className="fontf">{intl.formatMessage({ id: "innerpage-166" })}</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography><span className="fontfl">
                      {intl.formatMessage({ id: "innerpage-167" })}
          </span></Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}><b className="fontf">{intl.formatMessage({ id: "innerpage-168" })}</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography><span className="fontfl">
                      {intl.formatMessage({ id: "innerpage-169" })}
          </span></Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.heading}><b className="fontf">{intl.formatMessage({ id: "innerpage-170" })}</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography><span className="fontfl">
                        
                      {intl.formatMessage({ id: "innerpage-171" })}

          </span></Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </FadeIn>
            </div>

          </div>
        </div>
      </FadeIn>
    </Layout>

  );
};

export default FrequentlyAskedQuestions;
